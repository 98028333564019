.bg-primary-custom {
  background-color: #4d8cc3 !important;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-menu-item-only-child {
  font-size: 90%;
}

.bg-primary {
  background-color: #568bda !important;
}
.ant-table table {
  border-collapse: collapse;
}

/* [class^="ant-table"],
[class*=" ant-table"] {
  font-size: 100% !important;
} */

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 18px;
  font-size: 80% !important;
}

.ant-table-thead tr th {
  background-color: #4882b5 !important;
  color: #fff !important;
}

.btn-primary-custom {
  background-color: #3a8fda !important;
  border-color: #3a8fda !important;
  color: #fff;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-select-item-option-content {
  font-size: 85%;
}

.ant-picker {
  width: 100%;
}

.ant-transfer-list-content {
  font-size: 82%;
}
.ant-transfer-list {
  width: inherit !important;
}
.ant-transfer .ant-transfer-list-body {
  width: 42vw;
}
.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.table-responsive {
  width: 100%;
}

.text-danger {
  color: #dc3545 !important;
}

.text-success {
  color: #28a745 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-right {
  text-align: right;
}

.Spreadsheet__cell {
  min-width: 8em !important;
}

/* .ant-transfer .ant-transfer-list-body {
  width: 43vw !important;
} */

@media (max-width: 1520px) {
  .ant-transfer .ant-transfer-list-body {
    width: 40vw !important;
  }
}

.ant-space-compact .ant-btn {
  border-radius: 3px !important;
}

.font-90-link {
  color: #0063cf;
  font-weight: 500;
  font-size: 90%;
  cursor: pointer;
}
